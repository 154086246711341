<template>
  <CartList />
</template>

<script>
import CartList from "@/esf_antarctica_lid/core/components/cart/CartList.vue"
export default {
  name: "Cart",
  components: {
    CartList
  },

};
</script>

