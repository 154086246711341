<template>
  <div class="cart-page">
    <Breadcrumbs />
    <section class="cart pt-8">
      <b-container>
        <div class="cart-title">
          <span class="page-title semiBold-18">{{
            $t("shopping_basket")
          }}</span>

          <div class="options" v-if="cartItems.length > 0">
            <ClientOnly>
              <b-link href="/"><i class="fas fa-chevron-left"></i>{{ $t("continue_shopping") }}</b-link>
            </ClientOnly>
            <b-button @click="$router.push('/checkout')" variant="success" class="text-light py-6 pr-20 pl-15">{{
              $t("continue_buying") }}</b-button>
          </div>
        </div>
        <h3 class="semiBold-15 text-danger mb-5" v-if="cartItems.length === 0">
          {{ $t("no_cart") }}
        </h3>
        <div class="cart-item-row position-relative is-desktop" v-if="cartItems.length > 0">
          <table>
            <thead>
              <tr>
                <th class="product-img-title">{{ this.$t("items") }}</th>
                <th class="product-count-title">{{ this.$t("number") }}</th>
                <th class="product-price-title text-right">
                  {{ $t("unit_price") }}
                </th>
                <th class="product-price-title text-right">
                  {{ $t("total") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cartItem of cartItems" :key="cartItem.id">
                <td class="product-img">
                  <div @click="removeItem(cartItem.id)" style="cursor: pointer; display: flex">
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </div>
                  <div class="wrap">
                    <b-link :to="`/${cartItem.product.url_key}`">
                      <div class="img" :style="{
                        backgroundImage: `url(${cartItem.product.image.url})`,
                      }"></div>
                    </b-link>
                    <div class="product-info">
                      <div class="product text-blue">
                        <b-link :to="`/${cartItem.product.url_key}`">
                          <span class="product--name">{{
                            cartItem.product.name
                          }}</span>
                        </b-link>
                        <div v-if="typeof cartItem.configurable_options != 'undefined'
                            ">
                          <div v-for="(
                              option, index
                            ) of cartItem.configurable_options" :key="index" class="size-wrap">
                            <span class="size">{{ option.option_label }}:
                            </span>
                            <span class="size-box">{{
                              option.value_label
                            }}</span>
                          </div>
                        </div>
                        <div class="cart-bundle-list" v-if="cartItem.bundle_options !== undefined">
                          <ul>
                            <li v-for="option of cartItem.bundle_options" :key="option.id">
                              <span>{{ option.label }}</span>
                              <div class="sub">
                                <span>
                                  {{ option.values[0].quantity }}X{{
                                    option.values[0].label
                                  }}
                                </span>
                                <span>
                                  {{ formatCurrency(option.values[0].price) }}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td class="product-count">
                  <div class="quantity-input">
                    <b-link class="step-down-btn" @click="quantityDown(cartItem.id, cartItem.quantity)"></b-link>
                    <input type="number" min="1" :value="cartItem.quantity" name="quantity" ref="inputQuantity"
                      disabled />
                    <b-link class="step-up-btn" @click="quantityUp(cartItem.id, cartItem.quantity)"></b-link>
                  </div>
                </td>
                <td class="product-price text-right">
                  <span class="product--price">{{
                    formatCurrency(
                      cartItem.prices.row_total_including_tax.value /
                      cartItem.quantity
                    )
                  }}</span>
                </td>
                <td class="product-price text-right">
                  <span class="product--price">{{
                    formatCurrency(
                      cartItem.prices.row_total_including_tax.value
                    )
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="is-mobile" v-if="cartItems.length > 0">
          <div class="mobile-cart">
            <div class="cart-wrap">
              <div class="cart-item" v-for="cartItem of cartItems" :key="cartItem.id">
                <div class="cart-product-img">
                  <div @click="removeItem(cartItem.id)">
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </div>
                  <div class="img" :style="{
                    backgroundImage: `url(${cartItem.product.image.url})`,
                  }"></div>
                </div>
                <div class="cart-product-info">
                  <h3>{{ cartItem.product.name }}</h3>

                  <div v-if="cartItem.configurable_options">
                    <div v-for="(option, index) of cartItem.configurable_options" :key="index" class="size-wrap">
                      <span class="size">{{ option.option_label }}: </span>
                      <span class="size-box">{{ option.value_label }}</span>
                    </div>
                  </div>
                  <div class="cart-bundle-list" v-if="cartItem.bundle_options !== undefined">
                    <ul>
                      <li v-for="option of cartItem.bundle_options" :key="option.id">
                        <span>{{ option.label }}</span>
                        <div class="sub">
                          <span>
                            {{ option.values[0].quantity }}X{{
                              option.values[0].label
                            }}
                          </span>
                          <span>
                            {{ formatCurrency(option.values[0].price) }}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="pq-wrap">
                    <div class="product-count">
                      <div class="quantity-input">
                        <b-link href="#" class="step-down-btn"
                          @click="quantityDown(cartItem.id, cartItem.quantity)"></b-link>
                        <input type="number" min="1" :value="cartItem.quantity" name="quantity" ref="inputQuantity"
                          disabled />
                        <b-link href="#" class="step-up-btn" @click="quantityUp(cartItem.id, cartItem.quantity)"></b-link>
                      </div>
                    </div>
                    <span class="product--price">{{
                      formatCurrency(
                        cartItem.prices.row_total_including_tax.value
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="total-part" v-if="cartItems.length > 0">
          <div class="options is-mobile">
            <b-button @click="$router.push('/checkout')" variant="success" class="text-light py-6 pr-20 pl-15">{{
              $t("continue_buying") }}</b-button>
            <ClientOnly>
              <b-link href="/"><i class="fas fa-chevron-left"></i>{{ $t("continue_shopping") }}</b-link>
            </ClientOnly>
          </div>
          <div class="left">
            <button @click="emptyCart" class="delete-all-link is-desktop">
              <b-icon icon="trash" aria-hidden="true"></b-icon>
              <span>{{ this.$t('clear_all') }}</span>
            </button>

            <div class="discount-total-wrap loyalty-wrap" v-if="isLoggedIn">
              <div class="coupon-discount loyalty-wrap-input">
                <label>{{ this.$t("cartlist_pts") }}?</label>
                <form action="#" class="d-flex">
                  <input type="text" class="flex-grow-1" name="discount-code"
                    :placeholder="this.$t('kluspunten toepassen')" v-model="couponCode" />
                  <b-button @click="addCoupon" class="btn c-btn c-btn-primary">{{ $t("apply") }}</b-button>
                </form>
              </div>
            </div>

            <div class="discount-total-wrap">
              <div class="coupon-discount">
                <form action="#" class="d-flex">
                  <input type="text" class="flex-grow-1" name="discount-code" :placeholder="$t('fill_out_discount_code')"
                    v-model="couponCode" />
                  <b-button @click="addCoupon" class="btn c-btn c-btn-primary">{{ $t("apply") }}</b-button>
                </form>
              </div>
            </div>

            <div class="free_shipping" v-if="freeShippingAmount > 0">
              {{
                $t("free_shipping_msg", {
                  remain: freeShippingAmount.toFixed(2).replace(".", ","),
                })
              }}
            </div>
            <div class="free_shipping" v-else>
              {{ $t("free_shipping_msg_free") }}
            </div>
          </div>
          <div class="right">
            <div class="cost">
              <ul class="prices">
                <li>
                  <span class="name">{{ $t("total_shipping_cart") }} </span>
                  <span class="value">{{
                    formatCurrency(cartTotalWithOutTax)
                  }}</span>
                </li>
                <li v-if="defaultShipping != null" class="">
                  <span class="name">{{ $t("shipping_cost_estimated") }} </span>
                  <span class="value" v-if="defaultShipping.amount">{{
                    formatCurrency(defaultShipping.amount.value)
                  }}</span>
                </li>
                <li class="" v-for="(discount, index) in cartTotalDiscounts" :key="index">
                  <span class="name">{{ discount.label }}</span>
                  <span class="value">- {{ formatCurrency(discount.amount.value) }}</span>
                </li>
                <li class="" v-for="(price, index) in cartTotalTaxPrices" :key="index">
                  <span class="name">{{ price.label }}</span>
                  <span class="value">{{
                    formatCurrency(price.amount.value)
                  }}</span>
                </li>
              </ul>
              <div class="total-wrap">
                <span class="grand-totla">{{ $t("total_btw") }}</span>
                <span class="grand-price ml-10">{{
                  formatCurrency(cartTotalPrice)
                }}</span>
              </div>
            </div>
            <div class="checkout-btn">
              <ClientOnly>
                <b-link to="/"><i class="fas fa-chevron-left"></i>{{ $t("continue_shopping") }}</b-link>
              </ClientOnly>
              <b-button @click="$router.push('/checkout')" variant="success" class="text-light py-6 pr-20 pl-15">
                {{ $t("continue_buying") }}</b-button>
            </div>
          </div>
          <button @click="emptyCart" class="delete-all-link is-mobile">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
            <span>{{ this.$t("clear_all") }}</span>
          </button>
        </div>
      </b-container>
    </section>
    <section class="more-products" v-if="related && related.length > 0">
      <div class="container">
        <div class="section-title with-border">
          <h2 class="semiBold-18">{{ $t("other_bought") }}</h2>
        </div>
        <ClientOnly>
          <MoreProductsCarousel :products="related" />
        </ClientOnly>
      </div>
      <CompareButton></CompareButton>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import MoreProductsCarousel from "@/esf_antarctica_lid/core/components/product-detail/MoreProductsCarousel.vue";
import CompareButton from "@/base/core/components/common/CompareButton.vue";
import ClientOnly from "vue-client-only";

import Cart from "@storefront/core/modules/cart/mixins";
import { Logger } from "@storefront/core/lib/logger";
import { mapGetters } from "vuex";

import config from "@config";

export default {
  name: "CartPage",
  components: {
    Breadcrumbs,
    MoreProductsCarousel,
    ClientOnly,
    CompareButton,
  },
  mixins: [Cart],
  data() {
    return {
      noOfItems: 1,
      couponCode: "",
      prices: [
        {
          id: "price1",
          name: "Verzendkosten (Verzenden - Via PostNL of DHL)",
          cost: "€ 0,00",
          timeDelivery: "Levertijd: 2 tot 4 werkdagen.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      related: "product/getRelated",
      isLoggedIn: "user/getIsLoggedIn",
    }),
    freeShippingAmount() {
      return this.$store.getters["cart/getFreeShippingAmount"];
    },
    cartTotalWithOutTax: function () {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_excluding_tax != "undefined") {
            if (typeof prices.subtotal_excluding_tax.value != "undefined") {
              return prices.subtotal_excluding_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    totalCartTax: function () {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        let totalTax = { value: 0 };
        if (
          typeof prices != "undefined" &&
          typeof prices.applied_taxes == "object"
        ) {
          prices.applied_taxes.forEach(function (amount) {
            Logger.debug(
              "totalCartTax amount",
              "cartList",
              amount.amount.value
            )();
            totalTax.value += amount.amount.value;
          });
        }
        Logger.debug("totalCartTax", "cartList", [
          { amount: totalTax, label: `$t("VAT")` },
        ])();
        return [{ amount: totalTax, label: this.$t("VAT") }];
      }
      //return [{ amount: {'value':0}, label: this.$t("VAT") }];
      return this.cartTotalTaxPrices;
    },
  },
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    emptyCart() {
      this.cartItems.forEach((el) => this.removeItem(el.id));
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        Logger.debug("addCoupon", "cart.vue", retval)();
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.$t("shopping_basket"),
        },
        {
          name: "keywords",
          content: this.$t("shopping_basket"),
        },
        {
          name: "description",
          content: this.$t("shopping_basket"),
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("shopping_basket"),
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
  mounted() {
    const bcrumb = { current: this.$t("cart"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    if (this.$gtm.enabled()) {
      if (this.$gtm.ga4Enabled) {
        let items = [];
        this.cartItems.forEach((element) => {
          let i = {
            item_id: element.product.sku,
            item_name: element.product.name,
            quantity: element.quantity,
            currency: "EUR",
            price: element.prices.price.value,
          };
          items.push(i);
        });
        //GA4 event
        window.dataLayer.push({
          event: "view_cart",
          ecommerce: {
            add: {
              currency: "EUR",
              value: this.cartTotalWithOutTax,
              items: items,
            },
          },
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
