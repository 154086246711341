<template>
   <CartList />
</template>

<script>
import CartList from "@/base/core/components/cart/CartList.vue"
export default {
  name: "Cart",
  components: {
  CartList
  },
  
};
</script>

